<template>
  <div class="MapChart" ref="mapref"></div>
</template>
  
<script>
import debounce from 'lodash/debounce';
import * as echarts from "echarts";
import { MAP } from './map';

export default {
  name: 'CenterMap',
  props: {
    city: {
      type: String,
      dafault: ''
    }
  },
  data() {
    return { myChart: null, clickCount: 0, lastClickedArea: null }
  },
  watch: {
    city() {
      this.initChart();
    }
  },
  async mounted() {
    await this.$nextTick();
    let __resizeHandler = debounce(() => {
      if (this.myChart) {
        this.myChart.resize();
      }
    }, 100);
    window.addEventListener("resize", __resizeHandler);
    await this.initChart();
  },
  beforeDestroy() {
    if (this.myChart) {
      this.myChart.dispose();
    }
  },
  methods: {
    initChart() {
      if (this.myChart) {
        this.myChart.dispose();
      }
      this.myChart = echarts.init(this.$refs['mapref']);
      // 绘制图表
      var mapJson = MAP[this.city]
      echarts.registerMap('area', mapJson);
      var outdata = []; //地图区域挂载数据
      var option = {
        grid: {
          left: '1%',
          right: '1%',
          top: '1%',
          bottom: '1%',
          show: false,
          // backgroundColor:'#000'
        },
        // 地图的阴影底图
        geo: {
          map: 'area',
          left: 0,
          right: 0,
          bottom: 0,
          top: 0,
          aspectScale: 0.9,
          layoutCenter: ['50%', '52.5%'], //地图位置
          layoutSize: '110%',
          itemStyle: {
            normal: {
              shadowColor: 'rgba(0,228,242,0.5)',
              shadowBlur: 5,
              color: '#082A52',
            },
            emphasis: {
              areaColor: '#082A52',
            },
          },
          z: 2,
        },
        series: [
          {
            type: 'map',
            map: 'area',
            aspectScale: 0.8,
            layoutCenter: ['50%', '50%'], //地图位置
            layoutSize: '110%',
            label: {
              normal: {
                show: true,
                fontFamily: 'SourceHanSansCN',
                fontSize: '14',
                color: '#FEFEFE',
              },
              emphasis: {
                show: true,
                fontFamily: 'SourceHanSansCN',
                fontSize: '14',
                color: '#FEFEFE',
              },
            },
            // tooltip:{} tooltip可结合formatter、div、css实现样式
            itemStyle: {
              normal: {
                borderColor: 'rgba(0,0,0, 0.6)',
                borderWidth: 0.8,
                areaColor: {
                  type: 'linear-gradient',
                  x: 0,
                  y: 300,
                  x2: 0,
                  y2: 0,
                  colorStops: [
                    {
                      offset: 0,
                      color: '#0a56BC', // 0% 处的颜色
                    },
                    {
                      offset: 1,
                      color: '#12A6F2',
                    },
                  ],
                  global: true, // 缺省为 false
                },
              },
              emphasis: {
                shadowColor: 'rgba(0, 0, 0, 1)',
                shadowBlur: 10,
                shadowOffsetX: 5,
                shadowOffsetY: 5,
                areaColor: {
                  type: 'linear-gradient',
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [
                    {
                      offset: 0,
                      color: '#F5B615', // 0% 处的颜色
                    },
                    {
                      offset: 1,
                      color: '#E27B0d',
                    },
                  ],
                },
              },
            },
            zlevel: 1,
            data: outdata,
            emphasis: {
              focus: 'self', // 使得地图区域被点击后仍然保持高亮
            },
            selectedMode: 'single', // 设置为 'single' 才会触发 click 事件
          },
          //散点
          {
            type: 'effectScatter',
            coordinateSystem: 'geo',
            rippleEffect: {
              brushType: 'stroke',
            },
            showEffectOn: 'render',
            itemStyle: {
              normal: {
                color: {
                  type: 'radial',
                  x: 0.5,
                  y: 0.5,
                  r: 0.5,
                  colorStops: [
                    {
                      offset: 0,
                      color: 'rgba(0,228,242,0.5)',
                    },
                    {
                      offset: 0.8,
                      color: 'rgba(0,228,242,0.8)',
                    },
                    {
                      offset: 1,
                      color: 'rgba(0,228,242,1)',
                    },
                  ],
                  global: false, // 缺省为 false
                },
              },
            },
            symbol: 'circle',
            symbolSize: function (val) {
              // return (val[2] / maxData) * 4;
              return 10
            },
            data: [],
            zlevel: 1,
          },
        ],
      };

      this.myChart.clear();
      this.myChart.setOption(option);
      this.myChart.on('click', (params) => {
        if (params.name) {
          if (params.name !== this.lastClickedArea) {
            this.clickCount = 0;
          }
          if (this.clickCount % 2 === 0) {
            this.$emit('selectedCity', params.name);
          } else {
            this.$emit('selectedCity', '');
          }
          this.lastClickedArea = params.name;
          this.clickCount++;
        }
      });
    }
  }
}
</script>
  
<style scoped>
.MapChart {
  width: 550px;
  height: 700px;
}
</style>
  