

<template>
    <div class="algocard" @click="showAlgo(config)">
        <div class="topinfo">
            <img src="@/shell/assets/images/home/algoicon.png" alt="algoicon">
            <div class="algodetails">
                <div class="algoname">{{config}}
                </div>
                <div class="algodate">开发中</div>
            </div>
        </div>
        <div class="bottominfo">
        </div>
    </div>
</template>

<script>

export default {
    name:'FakeAlgo',
    props:{
        config:{
            type:String,
            default:''
        }
    },
    methods:{
        showAlgo(config){
            this.$emit('showModal',config)
        }
    }
}

</script>

<style lang="scss" scoped>
.algocard {
    width: 100%;
    height: 104px;
    opacity: 1;
    border-radius: 10px;
    background: rgba(188, 194, 205,.2);
    margin-bottom: 15px;
    padding: 15px 20px;
    cursor: pointer;
    .topinfo {
        width: 100%;
        display: flex;
        flex-direction: row;
        gap: 20px;
        margin-bottom: 12px;
        img {
            width: 47px;
            height: 47px;
        }
        .algodetails {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            gap: 3px;
            width: 100%;
            .algoname {
                height: 20px;
                opacity: 1;
                /** 文本1 */
                font-size: 18px;
                font-weight: 500;
                letter-spacing: 0px;
                color: var(--lightText);
                i {
                    float: right;
                }
            }
            .algodate {
                height: 20px;
                opacity: 1;
                /** 文本1 */
                font-size: 14px;
                font-weight: 400;
                letter-spacing: 0px;
                line-height: 20px;
                color: var(--lighterText);
            }

        }
    }
    .bottominfo {
        width: 100%;
        display: flex;
        flex-direction: row; 
        justify-content: space-between;
        gap:32px;
        .call {
            display: flex;
            flex-direction: row;
            gap: 12px; 
            align-items: baseline;
            img {
                width: 10px;
                height: 10px;
            }
            .callnums {
                height: 20px;
                opacity: 1;
                /** 文本1 */
                font-size: 14px;
                font-weight: 400;
                letter-spacing: 0px;
                color: var(--lighterText);
            }
        }
        .status {
            display: flex;
            flex-direction: row; 
            gap:12px;
            align-items: baseline;
            img {
                width: 10px;
                height: 10px;
            }
            .callstatus {
                height: 20px;
                opacity: 1;
                /** 文本1 */
                font-size: 14px;
                font-weight: 400;
                letter-spacing: 0px;
                color: var(--lighterText);
                .statusdot {
                    width: 11px;
                    height: 11px;
                    border-radius: 50%;
                    opacity: 1;
                    margin-right: 8px;
                    margin-left: 8px;
                    display: inline-block;
                }
                .normaldot {
                    background: var(--success);
                }
                .errordot{
                    background: rgba(252,88,73, 1);
                }
                .statusdesc {
                    opacity: 1;
                    font-size: 14px;
                    font-weight: 400;
                    letter-spacing: 0px;
                }
                .normal {
                    color: var(--success);
                }
                .error{
                    color: rgba(252,88,73, 1);
                }
            }
        }
    }
}
.algocard:hover {
    background: rgba(23, 124, 255, 1);
    .topinfo {
        .algodetails {
            .algoname {
                color: #ffffff;
            }
            .algodate {
                color: #ffffff;
            }

        }
    }
    .bottominfo {
        .call {
            img {
                filter: brightness(600%);
            }
            .callnums {
                color: #ffffff;
            }
        }
        .status {
            img {
                filter: brightness(600%);
            }
            .callstatus {
                color: #ffffff;
            }
        }
    }
}
</style>
