<template>
    <div class="centergraph" ref="mapref">
        <div class="graphtitle">{{ title }}</div>
        <div class="graphbox" ref="ivc">
        </div>
    </div>
</template>
    
<script>
import debounce from 'lodash/debounce';
import * as echarts from "echarts";
import { indicatorapi } from '@/shell/components/home/indicator'

export default {
    name: 'IVCGraph',
    props: {
        title: {
            type: String,
            dafault: ''
        },
        city: {
            type: String,
            dafault: ''
        }
    },
    data() {
        return { myChart: null, graphData: null }
    },
    created() {
        this.init()
    },
    watch: {
        city: function (o, n) {
            this.init()
        },
    },
    async mounted() {
        await this.$nextTick();
        let __resizeHandler = debounce(() => {
            if (this.myChart) {
                this.myChart.resize();
            }
        }, 100);
        window.addEventListener("resize", __resizeHandler);
    },
    methods: {
        init() {
            const url = indicatorapi.industryValueChain
            const data = {
                "city": this.city === 'NANJING' ? '南京市' : '无锡市',
                "currentYear": 2022,
                "province": "江苏省"
            }
            this.$axios.post(url, data).then(async res => {
                this.graphData = await res.data.data
                await this.initChart(this.graphData)
            })
        },
        initChart(data) {
            this.myChart = echarts.init(this.$refs['ivc']);
            // 绘制图表
            const districtNames = data.map(item => item.district);
            const indicatorNames = [
                "techServRevenueRate",
                "bachelorAboveRate",
                "avgTechContractAmount",
                "netIncrRevenue",
                "coProfitRate",
                "netIncrTechCoCnt",
                "ventureCapitalCoCnt",
                "patentInRevenueRate",
                "coAddedValueRate",
                "innovPolicyScore"
            ];
            const IndicatorNames = [
                '营业收入中高技术服务业营收占比',
                '从业人员中本科及以上学历人员占比',
                '人均技术合同成交额',
                '当年净增营业收入',
                '企业利润率',
                '当年净增高新技术企业数',
                '当年获得风险投资的企业数',
                '发明专利在每百亿营收占比',
                '区企业增加值率',
                '区产业创新政策评分',
            ];

            const seriesData = indicatorNames.map(indicator => ({
                name: indicator,
                type: 'line',
                data: data.map(item => item[indicator]),
            }));
            const logAxisIndicators = ['patentInRevenueRate'];
            const seriesDataWithLogAxis = seriesData.map(series => {
                if (logAxisIndicators.includes(series.name)) {
                    return { ...series, yAxisIndex: 1 };
                }
                return series;
            });
            this.myChart.setOption({
                legend: {
                    type: "scroll",
                    top: '0',
                    formatter: (name) => {
                        const index = indicatorNames.indexOf(name)
                        return IndicatorNames[index];
                    },
                },
                grid: {
                    left: '15%',
                    right: '15%',
                    show: true,
                    // backgroundColor:'#000'
                },
                xAxis: {
                    type: 'category',
                    data: districtNames,
                    boundaryGap: false,
                    axisLabel: {
                        interval: 0, // 强制显示所有标签
                        rotate: 45, // 标签旋转角度
                    },
                },
                yAxis: [{
                    type: 'value',
                }, {
                    type: 'log',
                }],
                series: seriesDataWithLogAxis,
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow',
                    },
                    formatter: (params) => {
                        const tooltipContentArr = []
                        params.map(param=>{
                            const tooltipContent = `
                                ${IndicatorNames[indicatorNames.indexOf(param.seriesName)]}: ${param.value}
                            `;
                            tooltipContentArr.push(tooltipContent)
                        })
                       
                        return tooltipContentArr.join('<br/>');
                    },
                },
            });
        }
    }
}
</script>
    
<style lang="scss" scoped >
.centergraph {
    width: 100%;
    height: 100%;

    .graphtitle {
        font-size: 14px;
        font-weight: 600;
        color: var(--lightText);
        height: 32px;
        width: 100%;
        line-height: 32px;
        margin: auto 0;
    }

    .graphbox {
        height: calc(100% - 32px);
        width: 100%;
        border-top: 1px solid #ccc;
    }
}
</style>
    