<template>
    <div class="centergraph" ref="mapref">
        <div class="graphtitle">{{ title }}</div>
        <div class="graphbox" ref="oic">
        </div>
    </div>
</template>
    
<script>
import debounce from 'lodash/debounce';
import * as echarts from "echarts";
import { indicatorapi } from '@/shell/components/home/indicator'

export default {
    name: 'OICGraph',
    props: {
        title: {
            type: String,
            dafault: ''
        },
        city: {
            type: String,
            dafault: ''
        }
    },
    data() {
        return { myChart: null, graphData: null }
    },
    created() {
        this.init()
    },
    watch: {
        city: function (o, n) {
            this.init()
        },
    },
    async mounted() {
        await this.$nextTick();
        let __resizeHandler = debounce(() => {
            if (this.myChart) {
                this.myChart.resize();
            }
        }, 100);
        window.addEventListener("resize", __resizeHandler);
    },
    methods: {
        init() {
            const url = indicatorapi.openInnoInterCompetitiveness
            const data = {
                "city": this.city === 'NANJING' ? '南京市' : '无锡市',
                "currentYear": 2022,
                "province": "江苏省"
            }
            this.$axios.post(url, data).then(async res => {
                this.graphData = await res.data.data
                await this.initChart(this.graphData)
            })
        },
        initChart(data) {
            this.myChart = echarts.init(this.$refs['oic']);
            // 绘制图表
            const districtNames = data.map(item => item.district);
            const indicatorNames = [
                "domestic_with_oversea_rnd_org_cnt",
                "rnd_costs_in_revenue_rate",
                "domestic_with_trademark_patent_cnt",
                "domestic_with_intl_std_cnt",
                "tech_serv_in_export_rate",
                "high_tech_export_in_revenue_rate",
                "foreign_resid_returned_stu_rate"
            ];
            const IndicatorNames = [
                '设境外研发机构内资控股企业数',
                '境内外产学研经费支出总额占营业收入比例',
                '获境外注册商标及发明专利授权的内资控股企业数',
                '新增主导制定国际标准的内资控股企业数',
                '出口总额中技术服务出口占比',
                '高新技术企业出口额占营业收入占比',
                '外籍常驻及留学在从业人中占比',
            ];
            const barArr = [
                "domestic_with_oversea_rnd_org_cnt",
                "domestic_with_trademark_patent_cnt",
                "domestic_with_intl_std_cnt",
            ]
            const seriesData = indicatorNames.map(indicator => ({
                name: indicator,
                type: barArr.includes(indicator) ? 'bar' : 'line',
                data: data.map(item => item[indicator]),
            }));
            this.myChart.setOption({
                legend: {
                    type: "scroll",
                    top: '0',
                    formatter: (name) => {
                        const index = indicatorNames.indexOf(name)
                        return IndicatorNames[index];
                    },
                },
                grid: {
                    left: '15%',
                    right: '15%',
                    show: true,
                    // backgroundColor:'#000'
                },
                xAxis: {
                    type: 'category',
                    data: districtNames,
                    boundaryGap: false,
                    axisLabel: {
                        interval: 0, // 强制显示所有标签
                        rotate: 45, // 标签旋转角度
                    },
                },
                yAxis: [{
                    type: 'value',
                }],
                series: seriesData,
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow',
                    },
                    formatter: (params) => {
                        const tooltipContentArr = []
                        params.map(param => {
                            const tooltipContent = `
                                ${IndicatorNames[indicatorNames.indexOf(param.seriesName)]}: ${param.value}
                            `;
                            tooltipContentArr.push(tooltipContent)
                        })

                        return tooltipContentArr.join('<br/>');
                    },
                },
            });
        }
    }
}
</script>
    
<style lang="scss" scoped >
.centergraph {
    width: 100%;
    height: 100%;

    .graphtitle {
        font-size: 14px;
        font-weight: 600;
        color: var(--lightText);
        height: 32px;
        width: 100%;
        line-height: 32px;
        margin: auto 0;
    }

    .graphbox {
        height: calc(100% - 32px);
        width: 100%;
        border-top: 1px solid #ccc;
    }
}
</style>
    