<template>
  <div class="centergraph" ref="mapref">
    <div class="graphtitle">{{ title }}</div>
    <div class="graphbox" ref="cqai">
    </div>
  </div>
</template>
    
<script>
import debounce from 'lodash/debounce';
import * as echarts from "echarts";
import { indicatorapi } from '@/shell/components/home/indicator'

export default {
  name: 'CQAIGraph',
  props: {
    title: {
      type: String,
      dafault: ''
    },
    city: {
      type: String,
      dafault: ''
    },
    district: {
      type: String,
      dafault: ''
    }
  },
  data() {
    return { myChart: null, graphData: null, districts: null }
  },
  created() {
    this.init()
  },
  watch: {
    city: function (o, n) {
      this.init()
    },
    district: function (o, n) {
      this.init()
    },
  },
  async mounted() {
    await this.$nextTick();
    let __resizeHandler = debounce(() => {
      if (this.myChart) {
        this.myChart.resize();
      }
    }, 100);
    window.addEventListener("resize", __resizeHandler);
  },
  methods: {
    transformDataForRadarChart(data) {
      const district = []
      const radarChartData = data.map(item => {
        district.push(item.district)
        return {
          name: item.district,
          value: [
            item.addedValueInCityGdpRate,
            item.laborEfficiencyIncrRate,
            item.fivePctRndRevenueRate,
            item.digRevenueRate,
            item.incrHighGrowthCoCnt,
            item.domesticForeignListedCnt,
            item.fivePctRnd5BillionRevenueCnt,
            item.nationalRndOrgCoCnt,
            item.legalConstructionScore,
            item.dataAccuracyScore,
          ],
        };
      });
      return { radarChartData, district };
    },
    init() {
      const url = indicatorapi.comprehensiveQualityAndInnovation
      const data = {
        "city": this.city === 'NANJING' ? '南京市' : '无锡市',
        "currentYear": 2022,
        "province": "江苏省"
      }
      this.$axios.post(url, data).then(async res => {
        this.graphData = await this.transformDataForRadarChart(res.data.data).radarChartData
        this.districts = await this.transformDataForRadarChart(res.data.data).district
        await this.initChart()
      })
    },
    convertToBooleanObject(districts, district) {
      if (!Array.isArray(districts)) {
        return null;
      }
      if (district === '') {
        const booleanObject = {};
        districts.forEach(districtitem => {
          booleanObject[districtitem] = true;
        });
        return booleanObject;
      } else {
        const booleanObject = {};
        districts.forEach(districtitem => {
          booleanObject[districtitem] = false;
        });
        booleanObject[district] = true;
        return booleanObject;
      }
    },
    initChart() {
      this.myChart = echarts.init(this.$refs['cqai']);
      // 绘制图表
      this.myChart.setOption({
        legend: {
          type: "scroll",
          top: '0',
          data: this.districts,
          selected: this.convertToBooleanObject(this.districts, this.district)
        },
        grid: {
          top: '10%',
          containLabel: true,

        },
        tooltip: {
          trigger: 'item',
          formatter: (params) => {
            const data = params.data;
            const tooltipContent = `
                ${params.marker} ${params.seriesName}: ${params.name}
                <br/>
                区全口径增加值占所在城市GDP比例: ${data.value[0]}
                <br/>
                全员劳动生产率的增长率: ${data.value[1]}
                <br/>
                研发达5%企业的营收合计占营业收入比例: ${data.value[2]}
                <br/>
                数字产业相关企业营收占比: ${data.value[3]}
                <br/>
                新晋高成长（瞪羚企业）企业数: ${data.value[4]}
                <br/>
                境内外上市（不含新三板）企业数: ${data.value[5]}
                <br/>
                研发投入强度达5%且营业收入超过5亿元的企业数: ${data.value[6]}
                <br/>
                拥有国家级研发机构的企业数: ${data.value[7]}
                <br/>
                区依法合规建设评分: ${data.value[8]}
                <br/>
                区数据统计精准度评分: ${data.value[9]}
              `;
            return tooltipContent;
          },
        },
        radar: {
          // shape: 'circle',
          indicator: [
            { name: '区全口径增加值占所在城市GDP比例', max: 1 },
            { name: '全员劳动生产率的增长率', max: 2 },
            { name: '研发达5%企业的营收合计占营业收入比例', max: 1 },
            { name: '数字产业相关企业营收占比', max: 1 },
            { name: '新晋高成长（瞪羚企业）企业数', max: 10 },
            { name: '境内外上市（不含新三板）企业数', max: 10 },
            { name: '研发投入强度达5%且营业收入超过5亿元的企业数', max: 5 },
            { name: '拥有国家级研发机构的企业数', max: 5 },
            { name: '区依法合规建设评分', max: 10 },
            { name: '区数据统计精准度评分', max: 10 },
          ],
        },
        series: [
          {
            name: '',
            type: 'radar',
            data: this.graphData
          },
        ],
      });
    }
  }
}
</script>
    
<style lang="scss" scoped >
.centergraph {
  width: 100%;
  height: 100%;

  .graphtitle {
    font-size: 14px;
    font-weight: 600;
    color: var(--lightText);
    height: 32px;
    width: 100%;
    line-height: 32px;
    margin: auto 0;
  }

  .graphbox {
    height: calc(100% - 32px);
    width: 100%;
    border-top: 1px solid #ccc;
  }
}
</style>
    