<template>
    <div class="centergraph" ref="mapref">
        <div class="graphtitle">{{ title }}</div>
        <div class="graphbox" ref="score">
        </div>
    </div>
</template>
    
<script>
import debounce from 'lodash/debounce';
import * as echarts from "echarts";
import { indicatorapi } from '@/shell/components/home/indicator'

export default {
    name: 'CenterGraph',
    props: {
        title: {
            type: String,
            dafault: ''
        },
        city: {
            type: String,
            dafault: ''
        }
    },
    data() {
        return { myChart: null, graphData: null }
    },
    created() {
        this.init()
    },
    watch: {
        city: function (o, n) {
            this.init()
        },
    },
    async mounted() {
        await this.$nextTick();
        let __resizeHandler = debounce(() => {
            if (this.myChart) {
                this.myChart.resize();
            }
        }, 100);
        window.addEventListener("resize", __resizeHandler);
    },
    methods: {
        init() {
            const url = indicatorapi.districtScores
            const data = {
                "city": this.city === 'NANJING' ? '南京市' : '无锡市',
                "currentYear": 2022,
                "province": "江苏省"
            }
            this.$axios.post(url, data).then(async res => {
                this.graphData = await res.data.data
                await this.initChart(this.graphData)
            })
        },
        initChart(data) {
            // 提取地区名称和各个指标的分数
            const districtNames = data.map(item => item.district);
            const ScoreCategories = ['创新能力和创业活跃度', '结构优化和产业价值链', '绿色发展和宜居包容性', '开放创新和国际竞争力', '综合质效和持续创新力'];
            const scoreCategories = ['innovationActivityScore', 'struOptiValueChainScore', 'ecoFriendlyLiveabilityScore', 'innovGlobalCompScore', 'integEffInnoScore'];
            const seriesData = scoreCategories.map(category => ({
                name: category,
                type: 'bar',
                stack: '总分',
                data: data.map(item => item[category]),
            }));
            this.myChart = echarts.init(this.$refs['score']);
            // 绘制图表
            this.myChart.setOption({
                legend: {
                    type: "scroll",
                    top: '0', 
                    formatter: (name) =>{
                        const index = scoreCategories.indexOf(name)
                        return ScoreCategories[index];
                    },
                },
                xAxis: {
                    type: 'category',
                    data: districtNames,
                    axisLabel: {
                        interval: 0, // 强制显示所有标签
                        rotate: 45, // 标签旋转角度
                    },
                },
                yAxis: {
                    type: 'value',
                },
                series: seriesData,
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow',
                    },
                    formatter: (params) => {
                        const tooltipContentArr = []
                        params.map(param=>{
                            const tooltipContent = `
                                ${ScoreCategories[scoreCategories.indexOf(param.seriesName)]}: ${param.value}
                            `;
                            tooltipContentArr.push(tooltipContent)
                        })
                       
                        return tooltipContentArr.join('<br/>');
                    },
                },
            });
        }
    }
}
</script>
    
<style lang="scss" scoped >
.centergraph {
    width: 100%;
    height: 100%;

    .graphtitle {
        font-size: 14px;
        font-weight: 600;
        color: var(--lightText);
        height: 32px;
        width: 100%;
        line-height: 32px;
        margin: auto 0;
    }

    .graphbox {
        height: calc(100% - 32px);
        width: 100%;
        border-top: 1px solid #ccc;
    }
}
</style>
    