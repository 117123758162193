<template>
  <div class="lineChart" :style="{ height: graphHeight + 'px' }" :ref="chartref"></div>
</template>

<script>
import debounce from 'lodash/debounce';
import * as echarts from "echarts";

export default {
  name: 'LinePlot',
  props: {
    color: {
      type: String,
      default: "green",
    },
    graphHeight: {
      type: Number,
      default: 0,
    },
    chartref: {
      type: String,
      default: "chartref",
    },
    xData: {
      type: Array,
      default: ()=>[],
    },
    yData: {
      type: Array,
      default: ()=>[],
    },
  },
  data() {
    return { myChart: null, }
  },
  async mounted() {
    await this.$nextTick();
    let __resizeHandler = debounce(() => {
      if (this.myChart) {
        this.myChart.resize();
      }
    }, 100);
    window.addEventListener("resize", __resizeHandler);
    await this.initChart();
  },
  methods: {
    initChart() {
      this.myChart = echarts.init(this.$refs[this.chartref]);
      // 绘制图表
      this.myChart.setOption({
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: this.xData,
        },
        grid: {
          top: 20,
          bottom: 20,
        },
        tooltip: {//提示框组件，用于配置鼠标滑过或点击图表时的显示框。
          show: true, // 是否显示
          trigger: 'axis', // 触发类型  'item'图形触发：散点图，饼图等无类目轴的图表中使用； 'axis'坐标轴触发；'none'：什么都不触发。
          axisPointer: { // 坐标轴指示器配置项。
              type: 'cross', // 'line' 直线指示器  'shadow' 阴影指示器  'none' 无指示器  'cross' 十字准星指示器。
            },
          // showContent: true, //是否显示提示框浮层，默认显示。
          // triggerOn: 'mouseover', // 触发时机'click'鼠标点击时触发。 
          backgroundColor: 'rgba(50,50,50,0.7)', // 提示框浮层的背景颜色。
          borderColor: '#333', // 提示框浮层的边框颜色。
          borderWidth: 0, // 提示框浮层的边框宽。
          padding: 5, // 提示框浮层内边距，
          textStyle: { // 提示框浮层的文本样式。
              color: '#fff',
              fontStyle: 'normal',
              fontWeight: 'normal',
              fontFamily: 'sans-serif',
              fontSize: 14,
          }
        },
        yAxis: { type: "value" },
        series: [
          {
            data: [77,111,4,50,147,149,148,157],
            color: this.color,
            areaStyle: {
              opacity: 0.8,
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: this.color,
                },
                {
                  offset: 1,
                  color: "#fff",
                },
              ]),
            },
            type: "line",
            smooth: true,
          },
        ],
      });
    }
  }
}
</script>

<style scoped>
.lineChart {
  width: 100%;
}
</style>
