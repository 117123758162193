var render = function render(){var _vm=this,_c=_vm._self._c;return _c('svg',[_c('image',{attrs:{"xlink:href":_vm.icon,"x":"26","y":"26","height":"50","width":"50"}}),_vm._v(" "),_c('circle',{attrs:{"cx":"51","cy":"51","r":"48","fill":"none","stroke":"#EEEEEE","stroke-width":"3"}}),_vm._v(" "),_c('circle',{style:({
                transform: 'rotate(-90deg)',
                transformOrigin: 'center',
                transformBox:'fill-box',
                stroke: _vm.config.color,

                strokeDasharray: `${_vm.currentLen} ${_vm.circleLength}`,
                transition: 'all 1s linear',
                strokeLinecap:'round',
            }),attrs:{"cx":"51","cy":"51","r":"48","fill":"none","stroke-width":"6"}})])
}
var staticRenderFns = []

export { render, staticRenderFns }