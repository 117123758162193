<script>
import { Card } from '@components/Card';
import Ring from '../../../../components/home/ring.vue'
import Algo from '../../../../components/home/algo.vue'
import CenterMap from '../../../../components/home/CenterMap.vue'
import CQAIGraph from '../../../../components/home/CQAIGraph.vue'
import ScoresGraph from '../../../../components/home/ScoresGraph.vue'
import IVCGraph from '../../../../components/home/IVCGraph.vue'
import OICGraph from '../../../../components/home/OICGraph.vue'
import GDLGraph from '../../../../components/home/GDLGraph.vue'
import IESGraph from '../../../../components/home/IESGraph.vue'
import FakeAlgo from '../../../../components/home/fakealgo.vue'
import HomeDashboardMetrics from '@shell/components/HomedashboardMetrics';
import { colorForState } from '@shell/plugins/dashboard-store/resource-class';
import { mapGetters } from 'vuex';
import LinePlot from '@shell/components/graph/LinePlot'
import {
  CAPI,
  ENDPOINTS,
  EVENT,
  NAMESPACE,
  INGRESS,
  MANAGEMENT,
  METRIC,
  NODE,
  SERVICE,
  PV,
  WORKLOAD_TYPES,
  COUNT,
  CATALOG,
  PSP
} from '@shell/config/types';
import { setPromiseResult } from '@shell/utils/promise';
import AlertTable from '@shell/components/AlertTable';
import { Banner } from '@components/Banner';
import { parseSi, createMemoryValues } from '@shell/utils/units';
import {
  NAME,
  ROLES,
  STATE
} from '@shell/config/table-headers';
import { SUANFA_MAP, REASON_MAP } from '@shell/config/mapConfig'
import grouping from '../../../../components/SortableTable/grouping';
import { mapPref, PSP_DEPRECATION_BANNER } from '@shell/store/prefs';
import { haveV1Monitoring, monitoringStatus } from '@shell/utils/monitoring';
import Tabbed from '@shell/components/Tabbed';
import Tab from '@shell/components/Tabbed/Tab';
import { allDashboardsExist } from '@shell/utils/grafana';
import EtcdInfoBanner from '@shell/components/EtcdInfoBanner';
import metricPoller from '@shell/mixins/metric-poller';
import EmberPage from '@shell/components/EmberPage';
import ResourceSummary, { resourceCounts } from '@shell/components/ResourceSummary';
import HardwareResourceGauge from '@shell/components/HardwareResourceGauge';
import { isEmpty } from '@shell/utils/object';
// import ConfigBadge from './ConfigBadge';
import EventsTable from './EventsTable';
import { fetchClusterResources } from './explorer-utils';
import SimpleBox from '@shell/components/SimpleBox';
import { ExtensionPoint, CardLocation } from '@shell/core/types';
import { getApplicableExtensionEnhancements } from '@shell/core/plugin-helpers';
import { monitorapi } from './monitor'
export const RESOURCES = [NAMESPACE, INGRESS, PV, WORKLOAD_TYPES.DEPLOYMENT, WORKLOAD_TYPES.STATEFUL_SET, WORKLOAD_TYPES.JOB, WORKLOAD_TYPES.DAEMON_SET, SERVICE];

const CLUSTER_METRICS_DETAIL_URL = '/api/v1/namespaces/cattle-monitoring-system/services/http:rancher-monitoring-grafana:80/proxy/d/rancher-cluster-nodes-1/rancher-cluster-nodes?orgId=1';
const CLUSTER_METRICS_SUMMARY_URL = '/api/v1/namespaces/cattle-monitoring-system/services/http:rancher-monitoring-grafana:80/proxy/d/rancher-cluster-1/rancher-cluster?orgId=1';
const K8S_METRICS_DETAIL_URL = '/api/v1/namespaces/cattle-monitoring-system/services/http:rancher-monitoring-grafana:80/proxy/d/rancher-k8s-components-nodes-1/rancher-kubernetes-components-nodes?orgId=1';
const K8S_METRICS_SUMMARY_URL = '/api/v1/namespaces/cattle-monitoring-system/services/http:rancher-monitoring-grafana:80/proxy/d/rancher-k8s-components-1/rancher-kubernetes-components?orgId=1';
const ETCD_METRICS_DETAIL_URL = '/api/v1/namespaces/cattle-monitoring-system/services/http:rancher-monitoring-grafana:80/proxy/d/rancher-etcd-nodes-1/rancher-etcd-nodes?orgId=1';
const ETCD_METRICS_SUMMARY_URL = '/api/v1/namespaces/cattle-monitoring-system/services/http:rancher-monitoring-grafana:80/proxy/d/rancher-etcd-1/rancher-etcd?orgId=1';

const COMPONENT_STATUS = [
  'etcd',
  'scheduler',
  'controller-manager',
];

export default {
  name: 'ClusterExplorerIndexPage',
  components: {
    HomeDashboardMetrics,
    Card,
    Ring,
    Algo,
    FakeAlgo,
    EtcdInfoBanner,
    HardwareResourceGauge,
    ResourceSummary,
    Tab,
    Tabbed,
    AlertTable,
    Banner,
    EmberPage,
    // ConfigBadge,
    EventsTable,
    SimpleBox,
    LinePlot,
    CenterMap,
    CQAIGraph,
    ScoresGraph,
    GDLGraph,
    IVCGraph,
    IESGraph,
    OICGraph
  },
  mixins: [metricPoller, grouping],
  async fetch() {
    // this.$store.dispatch('cluster/', EVENT);
    fetchClusterResources(this.$store, NODE);
    this.events = await fetchClusterResources(this.$store, EVENT);
    const deployments = await this.$store.dispatch('management/findAll', { type: WORKLOAD_TYPES.DEPLOYMENT })
    const algos = await deployments.filter(item => item.metadata.namespace === 'ai-center' && item.metadata.name.includes('suanfa-'))
    const order = ['ai-center/suanfa-recommend', 'ai-center/suanfa-value', 'ai-center/suanfa-keywords', 'ai-center/suanfa-mingan', 'ai-center/suanfa-text2vect', 'ai-center/suanfa-smartsessions', 'ai-center/suanfa-textcheck', 'ai-center/suanfa-embedding', 'ai-center/suanfa-techmatch', 'ai-center/suanfa-similaritymatch', 'ai-center/suanfa-policy-search', 'ai-center/suanfa-compliance-check', 'ai-center/suanfa-14bmodelggml']
    this.suanfaarr = algos.sort((a, b) => order.indexOf(a.id) - order.indexOf(b.id));
    const services = await this.$store.dispatch('cluster/findAll', { type: 'service' }, { root: true });
    this.apiarr = await services.filter(item => item.metadata.namespace === 'ai-center')
    if (this.currentCluster) {
      setPromiseResult(
        allDashboardsExist(this.$store, this.currentCluster.id, [CLUSTER_METRICS_DETAIL_URL, CLUSTER_METRICS_SUMMARY_URL]),
        this,
        'showClusterMetrics',
        `Determine cluster metrics`
      );
      setPromiseResult(
        allDashboardsExist(this.$store, this.currentCluster.id, [K8S_METRICS_DETAIL_URL, K8S_METRICS_SUMMARY_URL]),
        this,
        'showK8sMetrics',
        `Determine k8s metrics`
      );
      setPromiseResult(
        allDashboardsExist(this.$store, this.currentCluster.id, [ETCD_METRICS_DETAIL_URL, ETCD_METRICS_SUMMARY_URL]),
        this,
        'showEtcdMetrics',
        `Determine etcd metrics`
      );

      if (this.currentCluster.isLocal && this.$store.getters['management/schemaFor'](MANAGEMENT.NODE)) {
        this.$store.dispatch('management/findAll', { type: MANAGEMENT.NODE });
      }
    }
  },

  data() {
    const clusterCounts = this.$store.getters[`cluster/all`](COUNT);
    const nodeHeaders = [
      STATE,
      NAME,
      ROLES,
    ];

    return {
      homeMap: {
        city: 'NANJING',
        district: ''
      },
      xVisitedHis: [],
      yVisitedHis: [],
      xVisited: [],
      yVisited: [],
      graphHeight3: 0,
      interval: null,
      SUANFA_MAP: SUANFA_MAP,
      REASON_MAP: REASON_MAP,
      selectedAlgo: null,
      FakeselectedAlgo: '',
      apiarr: [],
      gpuindex: 0,
      suanfaarr: [],
      nodeHeaders,
      constraints: [],
      events: [],
      nodeMetrics: [],
      showClusterMetrics: false,
      showK8sMetrics: false,
      showEtcdMetrics: false,
      CLUSTER_METRICS_DETAIL_URL,
      CLUSTER_METRICS_SUMMARY_URL,
      K8S_METRICS_DETAIL_URL,
      K8S_METRICS_SUMMARY_URL,
      ETCD_METRICS_DETAIL_URL,
      ETCD_METRICS_SUMMARY_URL,
      clusterCounts,
      selectedTab: 'cluster-events',
      extensionCards: getApplicableExtensionEnhancements(this, ExtensionPoint.CARD, CardLocation.CLUSTER_DASHBOARD_CARD, this.$route),
      fakeAlgo: ['文本生成算法', '技术成熟度算法', '成果评估算法', '方案评估算法', '产业链分析算法', '质态分析算法']
    };
  },
  created() {
    this.interval = setInterval(this.mockapi, 3000);
    this.queryMonitor()
  },
  mounted() {
    const lineChartElement = document.getElementsByClassName('card')[0];
    if (lineChartElement) {
      this.graphHeight3 = lineChartElement.offsetHeight - 68;
    } else {
      console.warn("Card element not found.");
      this.graphHeight3 = 0
    }
  },
  beforeDestroy() {
    this.interval && clearInterval(this.interval);
    // Remove the data and stop watching resources that were fetched in this page
    // Events in particular can lead to change messages having to be processed when we are no longer interested in events
    this.$store.dispatch('cluster/forgetType', EVENT);
    this.$store.dispatch('cluster/forgetType', NODE);
    this.$store.dispatch('cluster/forgetType', ENDPOINTS); // Used by AlertTable to get alerts when v2 monitoring is installed
    this.$store.dispatch('cluster/forgetType', METRIC.NODE);
    this.$store.dispatch('cluster/forgetType', MANAGEMENT.NODE);
  },
  computed: {
    ...mapGetters(['currentCluster']),
    ...monitoringStatus(),
    displayPspDeprecationBanner() {
      const cluster = this.currentCluster;
      const major = cluster.status?.version?.major ? parseInt(cluster.status?.version?.major) : 0;
      const minor = cluster.status?.version?.minor ? parseInt(cluster.status?.version?.minor) : 0;

      if (major === 1 && minor >= 21 && minor < 25) {
        const clusterCounts = this.$store.getters[`cluster/all`](COUNT)?.[0]?.counts;

        return !!clusterCounts?.[PSP]?.summary?.count;
      }

      return false;
    },
    nodes() {
      return this.$store.getters['cluster/all'](NODE);
    },

    mgmtNodes() {
      return this.$store.getters['management/all'](MANAGEMENT.CLUSTER);
    },

    hidePspDeprecationBanner: mapPref(PSP_DEPRECATION_BANNER),

    hasV1Monitoring() {
      return haveV1Monitoring(this.$store.getters);
    },

    v1MonitoringURL() {
      return `/k/${this.currentCluster.id}/monitoring`;
    },

    displayProvider() {
      const other = 'other';

      let provider = this.currentCluster?.status?.provider || other;

      if (provider === 'rke.windows') {
        provider = 'rkeWindows';
      }

      if (!this.$store.getters['i18n/exists'](`cluster.provider.${provider}`)) {
        provider = 'other';
      }

      return this.t(`cluster.provider.${provider}`);
    },

    isHarvesterCluster() {
      return this.currentCluster?.isHarvester;
    },

    isRKE() {
      return ['rke', 'rke.windows', 'rke2', 'rke2.windows'].includes((this.currentCluster.status.provider || '').toLowerCase());
    },

    accessibleResources() {
      // This is a list of IDs for allowed resources counts.
      const defaultAllowedResources = Object.keys(this.clusterCounts?.[0]?.counts || {}).filter((typeId) => {
        return this.$store.getters['type-map/isIgnored']({ id: typeId });
      });

      // Merge with RESOURCES list
      const allowedResources = [...new Set([...defaultAllowedResources, ...RESOURCES])];

      return allowedResources.filter((resource) => this.$store.getters['cluster/schemaFor'](resource));
    },

    componentServices() {
      const status = [];

      COMPONENT_STATUS.forEach((cs) => {
        status.push({
          name: cs,
          healthy: this.isComponentStatusHealthy(cs),
          labelKey: `clusterIndexPage.sections.componentStatus.${cs}`,
        });
      });

      return status;
    },

    totalCountGaugeInput() {
      const totalInput = {
        name: this.t('clusterIndexPage.resourceGauge.totalResources'),
        total: 0,
        useful: 0,
        warningCount: 0,
        errorCount: 0
      };

      this.accessibleResources.forEach((resource) => {
        const counts = resourceCounts(this.$store, resource);

        Object.entries(counts).forEach((entry) => {
          totalInput[entry[0]] += entry[1];
        });
      });

      return totalInput;
    },

    hasStats() {
      return this.currentCluster?.status?.allocatable && this.currentCluster?.status?.requested;
    },

    cpuReserved() {
      const total = parseSi(this.currentCluster?.status?.allocatable?.cpu);

      return {
        total,
        useful: parseSi(this.currentCluster?.status?.requested?.cpu),
        units: this.t('clusterIndexPage.hardwareResourceGauge.units.cores', { count: total })
      };
    },

    podsUsed() {
      return {
        total: parseSi(this.currentCluster?.status?.allocatable?.pods || '0'),
        useful: parseSi(this.currentCluster?.status?.requested?.pods || '0'),
      };
    },

    ramReserved() {
      return createMemoryValues(this.currentCluster?.status?.allocatable?.memory, this.currentCluster?.status?.requested?.memory);
    },

    metricAggregations() {
      let checkNodes = this.nodes;

      // Special case local cluster
      if (this.currentCluster.isLocal) {
        const nodeNames = this.nodes.reduce((acc, n) => {
          acc[n.id] = n;

          return acc;
        }, {});

        checkNodes = this.mgmtNodes.filter((n) => {
          const nodeName = n.metadata?.labels?.['management.cattle.io/nodename'] || n.id;

          return !!nodeNames[nodeName];
        });
      }

      const someNonWorkerRoles = checkNodes.some((node) => node.hasARole && !node.isWorker);
      const metrics = this.nodeMetrics.filter((nodeMetrics) => {
        const node = this.nodes.find((nd) => nd.id === nodeMetrics.id);

        return node && (!someNonWorkerRoles || node.isWorker);
      });
      const initialAggregation = {
        cpu: 0,
        memory: 0
      };

      if (isEmpty(metrics)) {
        return null;
      }

      return metrics.reduce((agg, metric) => {
        agg.cpu += parseSi(metric.usage.cpu);
        agg.memory += parseSi(metric.usage.memory);

        return agg;
      }, initialAggregation);
    },

    cpuUsed() {
      const total = parseSi(this.currentCluster?.status?.capacity?.cpu);

      return {
        total,
        useful: this.metricAggregations?.cpu,
        units: this.t('clusterIndexPage.hardwareResourceGauge.units.cores', { count: total })
      };
    },

    ramUsed() {
      return createMemoryValues(this.currentCluster?.status?.capacity?.memory, this.metricAggregations?.memory);
    },

    hasMonitoring() {
      return !!this.clusterCounts?.[0]?.counts?.[CATALOG.APP]?.namespaces?.['cattle-monitoring-system'];
    },

    canAccessNodes() {
      return !!this.clusterCounts?.[0]?.counts?.[NODE];
    },

    canAccessDeployments() {
      return !!this.clusterCounts?.[0]?.counts?.[WORKLOAD_TYPES.DEPLOYMENT];
    },

    hasMetricsTabs() {
      return this.showClusterMetrics || this.showK8sMetrics || this.showEtcdMetrics;
    },

    hasBadge() {
      return !!this.currentCluster?.badge;
    },

    hasDescription() {
      return !!this.currentCluster?.spec?.description;
    },

    allEventsLink() {
      return {
        name: 'c-cluster-product-resource',
        params: {
          product: 'explorer',
          resource: 'event',
        }
      };
    },
    graphHeight() {
      return '120px'
    },
    graphHeight2() {
      return '132px'
    },
    gifSrc() {
      const algosarr = ['suanfa-embedding', 'suanfa-policy-search', 'suanfa-14bmodelggml']
      if (this.selectedAlgo?.metadata?.name !== null && !algosarr.includes(this.selectedAlgo?.metadata?.name)) {
        try {
          const url = require('@/shell/assets/images/home/' + this.selectedAlgo?.metadata?.name + '.gif')
          return url
        } catch (e) {
          return null
        }

      } else {

        return null
      }
    }
  },
  methods: {
    mockapi() {
      const url = '/k8s/clusters/local/api/v1/namespaces/cattle-monitoring-system/services/http:rancher-monitoring-prometheus:9090/proxy/api/v1/query?query=avg%28DCGM_FI_DEV_GPU_UTIL%29&time=' + Date.now() / 1000
      this.$axios.get(url).then(res => {
        this.gpuindex = res.data.data.result[0].value[1]
      })
    },
    gotofakegraph() {
      const { href } = this.$router.resolve({
        path: "/report",
      });
      window.open(href, '_blank');
    },
    gotofakegraph2() {
      const { href } = this.$router.resolve({
        path: "/company",
      });
      window.open(href, '_blank');
    },
    gotofakegraph3() {
      const { href } = this.$router.resolve({
        path: "/labs",
      });
      window.open(href, '_blank');
    },
    gotofakegraph4() {
      const { href } = this.$router.resolve({
        path: "/engineering",
      });
      window.open(href, '_blank');
    },
    gotofakegraph5() {
      const { href } = this.$router.resolve({
        path: "/dashbord",
      });
      window.open(href, '_blank');
    },
    gotofakegraph6() {
      const { href } = this.$router.resolve({
        path: "/maturity",
      });
      window.open(href, '_blank');
    },
    generateTimeArrayFromNow() {
      const currentTimestamp = Math.floor(Date.now() / 1000);
      const endTimestamp = currentTimestamp - (currentTimestamp % 3600); // Round down to the nearest hour

      const timestamps = [];
      for (let i = 7; i >= 0; i--) {
        const currentTimestamp = endTimestamp - i * 3600;
        const date = new Date(currentTimestamp * 1000);
        const hours = date.getHours();
        const minutes = date.getMinutes();
        const formattedTime = `${hours}:${minutes < 10 ? '0' : ''}${minutes}`;

        timestamps.push(formattedTime);
      }

      return { end: endTimestamp, timestamps };
    },
    queryMonitor() {
      const { end, timestamps } = this.generateTimeArrayFromNow()
      this.xVisited = timestamps
      const data1 = {
        "path": "/guashuai/api/batch_boarding_recommend_result",
        "status": 200,
        "start": 1703732400,
        "end": 1703757600,
        "step": "1h"
      }
      this.$axios.post(monitorapi.service_visited, data1).then(res => {
        this.yVisited = res.data.data
      })
    },
    colorToCountName(color) {
      switch (color) {
        case 'text-success':
        case 'text-info':
          return 'useful';
        case 'text-warning':
          return 'warningCount';
        default:
          return 'errorCount';
      }
    },
    resourceCounts(store, resource) {
      const inStore = store.getters['currentStore'](COUNT);
      const clusterCounts = store.getters[`${inStore}/all`](COUNT)?.[0]?.counts;
      const summary = clusterCounts?.[resource]?.summary || {};

      const counts = {
        total: summary.count || 0,
        useful: summary.count || 0,
        warningCount: 0,
        errorCount: 0
      };

      Object.entries(summary.states || {}).forEach((entry) => {
        const color = colorForState(entry[0]);
        const count = entry[1];
        const countName = this.colorToCountName(color);

        counts['useful'] -= count;
        counts[countName] += count;
      });

      return counts;
    },
    // Ported from Ember
    isComponentStatusHealthy(field) {
      const matching = (this.currentCluster?.status?.componentStatuses || []).filter((s) => s.name.startsWith(field));

      // If there's no matching component status, it's "healthy"
      if (!matching.length) {
        return true;
      }

      const count = matching.reduce((acc, status) => {
        const conditions = status.conditions.find((c) => c.status !== 'True');

        return !conditions ? acc : acc + 1;
      }, 0);

      return count === 0;
    },

    showActions() {
      this.$store.commit('action-menu/show', {
        resources: this.currentCluster,
        elem: this.$refs['cluster-actions'],
      });
    },

    // Used by metric-poller mixin
    async loadMetrics() {
      this.nodeMetrics = await fetchClusterResources(this.$store, METRIC.NODE, { force: true });
    },

    // Events/Alerts tab changed
    tabChange(neu) {
      this.selectedTab = neu?.selectedName;
    },

    async goToHarvesterCluster() {
      try {
        const provClusters = await this.$store.dispatch('management/findAll', { type: CAPI.RANCHER_CLUSTER });
        const provCluster = provClusters.find((p) => p.mgmt.id === this.currentCluster.id);

        await provCluster.goToHarvesterCluster();
      } catch {
      }
    },

    maxDecimalPlaces(n) {
      return Math.round(n * 100) / 100;
    },
    showModal(config) {
      this.selectedAlgo = config
      this.$modal.show('algomodal')
    },
    showFakeModal(algo) {
      this.FakeselectedAlgo = algo
      this.$modal.show('fakealgomodal')
    },
  },
};
</script>
<template>
  <div class="home-page" style="margin: 0;">
    <div class="leftpanel">
      <div class="top">
        <div class="cardtitle">AI中台概况</div>
        <div class="indexcontainer">
          <div class="indexbox">
            <div class="index">{{ apiarr.length }} <img src="@/shell/assets/images/home/icon1.png" alt="icon1"></div>
            <div class="desc">对外服务数量</div>
          </div>
          <div class="indexbox">
            <div class="index">5 <img src="@/shell/assets/images/home/icon2.png" alt="icon2"></div>
            <div class="desc">数据类型数量</div>
          </div>
          <div class="indexbox">
            <div class="index">{{ totalCountGaugeInput.total }} <img src="@/shell/assets/images/home/icon3.png"
                alt="icon3"></div>
            <div class="desc">资源总额</div>
          </div>
          <div class="indexbox">
            <div class="index">{{ resourceCounts($store, 'node')?.total }} <img
                src="@/shell/assets/images/home/icon4.png" alt="icon4"></div>
            <div class="desc">节点数</div>
          </div>
          <div class="indexbox">
            <div class="index">{{ resourceCounts($store, 'apps.deployment')?.total }} <img
                src="@/shell/assets/images/home/icon5.png" alt="icon5"></div>
            <div class="desc">部署总数</div>
          </div>
        </div>
      </div>
      <div class="bottom">
        <div class="monitor">
          <div class="cardtitle">算法监控&nbsp;&nbsp;<i v-tooltip="'各行政区各维度评价可视化展示'" class="icon icon-error"
              @click="$modal.show('fakegraphmodal')"></i>
            &nbsp;&nbsp;<i v-tooltip="'新型研发机构各维度指标统计'" class="icon icon-error" @click="gotofakegraph"></i>&nbsp;&nbsp;<i
              v-tooltip="'企业维度指标统计'" class="icon icon-error" @click="gotofakegraph2"></i>&nbsp;&nbsp;<i
              v-tooltip="'江苏省实验室指标计算'" class="icon icon-error" @click="gotofakegraph3"></i>&nbsp;&nbsp;<i
              v-tooltip="'国家工程技术研究中心指标计算'" class="icon icon-error" @click="gotofakegraph4"></i>&nbsp;&nbsp;
            <i v-tooltip="'首页'" class="icon icon-error" @click="gotofakegraph5"></i>&nbsp;&nbsp;
            <i v-tooltip="'成熟度评估'" class="icon icon-error" @click="gotofakegraph6"></i>
          </div>
          <div class="algolist">
            <Algo v-for="(item, index) in suanfaarr" :key="index" :config="item" @showModal="showModal" />
            <FakeAlgo v-for="item in fakeAlgo" :key="item" :config="item" @showModal="showFakeModal" />
          </div>
        </div>
        <div class="cards">
          <div class="card">
            <div class="cardtitle">服务接口访问统计</div>
            <div class="graph">
              <LinePlot chartref="server" :graphHeight='graphHeight3' color="#7948EA" :xData='xVisited'
                :yData='yVisited' />
            </div>
          </div>
          <div class="card">
            <div class="cardtitle">对外服务接收数据比率</div>
            <div class="graph">
              <HomeDashboardMetrics
                :detail-url="'/api/v1/namespaces/cattle-monitoring-system/services/http:rancher-monitoring-grafana:80/proxy/d/kS6_NkDSz/skj_home_rb?orgId=1'"
                :graph-height="graphHeight3 + 'px'" />
            </div>
          </div>
          <div class="card">
            <div class="cardtitle">磁盘同步时延</div>
            <div class="graph">
              <HomeDashboardMetrics
                :detail-url="'/api/v1/namespaces/cattle-monitoring-system/services/http:rancher-monitoring-grafana:80/proxy/d/V0IYp2IIk/skj_home-time?orgId=1'"
                :graph-height="graphHeight3 + 'px'" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="rightpanel">
      <div class="top">
        <div class="graph">
          <div class="ring">
            <div class="left">
              <Ring :config="{
                type: 'cpu',
                color: '#0163FF',
                rate: maxDecimalPlaces(cpuUsed.useful) / cpuUsed.total
              }" />
            </div>
            <div class="right">
              {{ (((maxDecimalPlaces(cpuUsed.useful) / cpuUsed.total) * 100).toFixed(1)) || 0 }}%
              <span>CPU</span>
            </div>
          </div>
          <div class="rightgraph">
            <HomeDashboardMetrics
              :detail-url="'/api/v1/namespaces/cattle-monitoring-system/services/http:rancher-monitoring-grafana:80/proxy/d/Qcw1smVIz/skj_home-cpu?orgId=1'"
              :graph-height="graphHeight" />
          </div>
        </div>
        <div class="graph">
          <div class="ring">
            <div class="left">
              <Ring :config="{
                type: 'gpu',
                color: '#7948EA',
                rate: gpuindex / 100
              }" />
            </div>
            <div class="right">
              {{ gpuindex || 0 }}%
              <span>GPU</span>
            </div>
          </div>
          <div class="rightgraph">
            <HomeDashboardMetrics
              :detail-url="'/api/v1/namespaces/cattle-monitoring-system/services/http:rancher-monitoring-grafana:80/proxy/d/EYqXFkDIk/skj_home_gpu?orgId=1'"
              :graph-height="graphHeight" />
          </div>
        </div>
        <div class="graph">
          <div class="ring">
            <div class="left">
              <Ring :config="{
                type: 'memory',
                color: '#25CB4A',
                rate: maxDecimalPlaces(ramUsed.useful) / ramUsed.total
              }" />
            </div>
            <div class="right">
              {{ (((maxDecimalPlaces(ramUsed.useful) / ramUsed.total) * 100).toFixed(1)) || 0 }}%
              <span>内存</span>
            </div>
          </div>
          <div class="rightgraph">
            <HomeDashboardMetrics
              :detail-url="'/api/v1/namespaces/cattle-monitoring-system/services/http:rancher-monitoring-grafana:80/proxy/d/AiOVxFSIk/skj_home-memory?orgId=1'"
              :graph-height="graphHeight" />
          </div>
        </div>
        <div class="graph">
          <div class="ring">
            <div class="left">
              <Ring :config="{
                type: 'disk',
                color: '#FC5849',
                rate: maxDecimalPlaces(podsUsed.useful) / podsUsed.total
              }" />
            </div>
            <div class="right">
              {{ (((maxDecimalPlaces(podsUsed.useful) / podsUsed.total) * 100).toFixed(1)) || 0 }}%
              <span>磁盘</span>
            </div>
          </div>
          <div class="rightgraph">
            <HomeDashboardMetrics
              :detail-url="'/api/v1/namespaces/cattle-monitoring-system/services/http:rancher-monitoring-grafana:80/proxy/d/OgAGJKISk/skj_home-disk?orgId=1'"
              :graph-height="graphHeight" />
          </div>
        </div>
      </div>
      <div class="bottom">
        <div class="mainstatus">
          <div class="cardtitle">事件通知</div>
          <div class="overview">
            <div class="overviewitem">
              <div class="overviewpic">
                <img src="@/shell/assets/images/home/overview1.png" alt="overview1">
              </div>
              <div class="overviewdesc">
                <span class="desctitle">算法模型</span>
                <span>算法模型更新...</span>
              </div>
              <div class="overviewindex">
                <div class="fakenum"></div>
                <div class="num">
                  i
                </div>
              </div>
            </div>
            <div class="overviewitem">
              <div class="overviewpic">
                <img src="@/shell/assets/images/home/overview2.png" alt="overview2">
              </div>
              <div class="overviewdesc">
                <span class="desctitle">数据处理流程</span>
                <span>数据更新...</span>
              </div>
              <div class="overviewindex">
                <div class="fakenum"></div>
                <div class="num">
                  i
                </div>
              </div>
            </div>
            <div class="overviewitem">
              <div class="overviewpic">
                <img src="@/shell/assets/images/home/overview3.png" alt="overview3">
              </div>
              <div class="overviewdesc">
                <span class="desctitle">API文档</span>
                <span>API文档更新...</span>
              </div>
              <div class="overviewindex">
                <div class="fakenum"></div>
                <div class="num">
                  i
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="logs">
          <div class="cardtitle">
            <div class="title">日志</div>
            <div class="actionlink">
              <nuxt-link to="/c/local/explorer/event">查看更多</nuxt-link>
              <div>
              </div>
              <div class="loglist">
                <div class="log" v-for="(item, index) in events" :key="index" :title="item.message">
                  <span>{{ REASON_MAP[item.reason] }}</span> {{ item.message }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- algo modal -->
        <modal name="algomodal" width="50%" :click-to-close="false" :height="'auto'" class="modal"
          data-testid="mvc__card">
          <Card class="modal" :showActions='false' :show-highlight-border="false">
            <template #title>
              <h4 slot="title" class="text-default-text" v-if="SUANFA_MAP[selectedAlgo?.metadata?.name]">
                {{ SUANFA_MAP[selectedAlgo?.metadata?.name] + '-介绍' }}
              </h4>
              <i class="icon icon-close" v-tooltip="'关闭弹框'" @click="$modal.hide('algomodal')"></i>
            </template>

            <template #body>
              <div class="algopreview" v-if="gifSrc !== null">
                <img :src="gifSrc" alt="algopreview">
              </div>
              <div class="algopreview warning" v-else>
                <svg xmlns="http://www.w3.org/2000/svg" width="160" height="160" fill="#DBE1EC" viewBox="0 0 48 48">
                  <path
                    d="M22 30h4v4h-4zm0-16h4v12h-4zm1.99-10C12.94 4 4 12.95 4 24s8.94 20 19.99 20S44 35.05 44 24 35.04 4 23.99 4zM24 40c-8.84 0-16-7.16-16-16S15.16 8 24 8s16 7.16 16 16-7.16 16-16 16z" />
                </svg>
                <h2> 暂无介绍 </h2>
              </div>
            </template>
          </Card>
        </modal>
        <!-- fakealgo modal -->
        <modal name="fakealgomodal" width="50%" :click-to-close="false" :height="'auto'" class="modal"
          data-testid="mvc__card">
          <Card class="modal" :showActions='false' :show-highlight-border="false">
            <template #title>
              <h4 slot="title" class="text-default-text">
                {{ FakeselectedAlgo + '-介绍' }}
              </h4>
              <i class="icon icon-close" v-tooltip="'关闭弹框'" @click="$modal.hide('fakealgomodal')"></i>
            </template>

            <template #body>
              <div class="algopreview warning">
                <svg xmlns="http://www.w3.org/2000/svg" width="160" height="160" fill="#DBE1EC" viewBox="0 0 48 48">
                  <path
                    d="M22 30h4v4h-4zm0-16h4v12h-4zm1.99-10C12.94 4 4 12.95 4 24s8.94 20 19.99 20S44 35.05 44 24 35.04 4 23.99 4zM24 40c-8.84 0-16-7.16-16-16S15.16 8 24 8s16 7.16 16 16-7.16 16-16 16z" />
                </svg>
                <h2> 暂无介绍 </h2>
              </div>
            </template>
          </Card>
        </modal>
        <!-- fakegraph modal -->
        <modal name="fakegraphmodal" width="90%" :click-to-close="false" :height="850" class="modal"
          data-testid="mvc__card">
          <Card class="modal" :showActions='false' :show-highlight-border="false">
            <template #title>
              <h4 slot="title" class="text-default-text">
                各行政区各维度评价可视化展示
              </h4>
              <i class="icon icon-close" v-tooltip="'关闭弹框'" @click="$modal.hide('fakegraphmodal')"></i>
            </template>
            <template #body>
              <div class="graphpreview">
                <div class="cityselect">
                  <label for="">城市切换：</label><select name="cityselect" v-model="homeMap.city">
                    <option value="NANJING">南京市</option>
                    <option value="WUXI">无锡市</option>
                  </select>
                </div>
                <div class="centermap">
                  <CenterMap :city="homeMap.city" @selectedCity="(val) => homeMap.district = val" />
                </div>
                <div class="gridgraph">
                  <div class="graph">
                    <CQAIGraph title="综合质效和持续创新力" :city="homeMap.city" :district="homeMap.district" />
                  </div>
                  <div class="graph">
                    <ScoresGraph title="各行政区各维度评分" :city="homeMap.city" />
                  </div>
                  <div class="graph">
                    <GDLGraph title="绿色发展和宜居包容性" :city="homeMap.city" />
                  </div>
                  <div class="graph">
                    <IVCGraph title="结构优化和产业价值链" :city="homeMap.city" />
                  </div>
                  <div class="graph">
                    <IESGraph title="创新能力和创业活跃度" :city="homeMap.city" />
                  </div>
                  <div class="graph">
                    <OICGraph title="开放创新和国际竞争力" :city="homeMap.city" />
                  </div>
                </div>
              </div>
            </template>
          </Card>
        </modal>
      </div>
    </div>
  </div>
</template>

<style lang='scss' scoped>
.home-page {
  background-color: #F5F7FA;
  padding: 16px;
  display: flex;
  flex-direction: row;
  gap: 20px;
  height: 100%;
  overflow: hidden;

  .leftpanel {
    flex: 840;
    display: flex;
    flex-direction: column;
    gap: 20px;

    .top {
      flex: 156;
      width: 100%;
      background: #ffffff;
      border-radius: 10px;
      padding: 24px;

      .cardtitle {
        height: 20px;
        font-size: 18px;
        font-weight: bold;
        font-family: 'Microsoft Yahei';
        letter-spacing: 0px;
        color: var(--text);
        display: flex;
      }

      .cardtitle::before {
        content: "";
        display: block;
        width: 6px;
        height: 20px;
        background-color: var(--primary);
        margin-right: 8px;
      }

      .indexcontainer {
        margin-top: 20px;
        display: flex;
        flex-direction: row;
        gap: 16px;
        height: calc(100% - 40px);

        .indexbox {
          flex: 1;
          border-radius: 10px;
          padding: 15px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          .index {
            font-size: 24px;
            font-family: 'SiYuanHeiTi';
            font-weight: 700;
            letter-spacing: 0px;
            color: var(--lightText);

            img {
              width: 26px;
              float: right;
            }
          }

          .desc {
            font-size: 14px;
            font-weight: 400;
            letter-spacing: 0px;
            color: var(--lightText);
            text-align: left;
          }
        }

        .indexbox:nth-of-type(1) {
          background: rgba(255, 226, 229, 1);
        }

        .indexbox:nth-of-type(2) {
          background: rgba(255, 244, 222, 1);
        }

        .indexbox:nth-of-type(3) {
          background: rgba(220, 252, 231, 1);
        }

        .indexbox:nth-of-type(4) {
          background: rgba(243, 232, 255, 1);
        }

        .indexbox:nth-of-type(5) {
          background: rgba(232, 239, 255, 1);
        }
      }
    }

    .bottom {
      flex: 744;
      display: flex;
      flex-direction: row;
      gap: 20px;
      overflow: hidden;

      .monitor {
        flex: 383;
        background: #ffffff;
        border-radius: 10px;
        padding: 24px 10px 24px 10px;
        position: relative;

        .cardtitle {
          height: 20px;
          font-size: 18px;
          font-weight: bold;
          font-family: 'Microsoft Yahei';
          letter-spacing: 0px;
          padding-left: 14px;
          color: var(--text);
          display: flex;
          align-items: center;

          i {
            cursor: pointer;
          }
        }

        .cardtitle::before {
          content: "";
          display: block;
          width: 6px;
          height: 20px;
          background-color: var(--primary);
          margin-right: 8px;
        }

        .algolist {
          overflow: scroll;
          position: absolute;
          left: 10px;
          top: 64px;
          width: calc(100% - 20px);
          height: -webkit-fill-available;
          padding-left: 14px;
          padding-right: 14px;

          .log {
            font-size: 16px;
            font-weight: 400;
            letter-spacing: 0px;
            color: var(--lightText);
            height: 56px;
            padding-top: 16px;
            padding-bottom: 16px;
            padding-left: 14px;
            padding-right: 14px;
            box-sizing: border-box;
            border-bottom: 1px dashed rgba(233, 233, 233, 1);
          }

          &::-webkit-scrollbar {
            width: 3px !important;
          }

          &::-webkit-scrollbar-thumb {
            background: rgba(238, 238, 238, 1) !important;
            border-radius: 2.5px !important;
          }
        }
      }

      .cards {
        flex: 437;
        display: flex;
        flex-direction: column;
        gap: 20px;

        .card {
          width: 100%;
          flex: 1;
          background: #ffffff;
          border-radius: 10px;
          padding: 24px;
          overflow: hidden;

          .cardtitle {
            font-size: 18px;
            font-weight: bold;
            font-family: 'Microsoft Yahei';
            letter-spacing: 0px;
            color: var(--text);
            display: flex;
          }

          .cardtitle::before {
            content: "";
            display: block;
            width: 6px;
            height: 20px;
            background-color: var(--primary);
            margin-right: 8px;
          }
        }
      }
    }
  }

  .rightpanel {
    flex: 764;
    display: flex;
    flex-direction: column;
    gap: 20px;
    overflow: hidden;

    .top {
      background: #ffffff;
      flex: 499;
      width: 100%;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      overflow-y: scroll;

      &::-webkit-scrollbar {
        width: 3px !important;
      }

      &::-webkit-scrollbar-thumb {
        background: rgba(238, 238, 238, 1) !important;
        border-radius: 2.5px !important;
      }

      .graph {
        flex: 1;
        padding: 30px 54px;
        display: flex;
        flex-direction: row;

        .ring {
          display: flex;
          flex: 238;
          flex-direction: row;
          gap: 33px;

          .left {
            svg {
              width: 102px;
              height: 102px;
            }
          }

          .right {
            font-size: 36px;
            font-weight: 700;
            letter-spacing: 0px;
            line-height: 20px;
            color: var(--lightText);
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 20px;

            span {
              font-size: 24px;
              font-weight: 400;
              letter-spacing: 0px;
              line-height: 20px;
              color: var(--lighterText);
            }
          }
        }

        .rightgraph {
          flex: 375;
        }
      }
    }

    .bottom {
      flex: 431;
      width: 100%;
      display: flex;
      gap: 20px;

      .mainstatus {
        height: 100%;
        width: calc((100% - 20px)/2);
        background: #ffffff;
        border-radius: 10px;
        padding: 30px 14px 30px 14px;

        .cardtitle {
          height: 20px;
          font-size: 18px;
          font-weight: bold;
          font-family: 'Microsoft Yahei';
          letter-spacing: 0px;
          color: var(--text);
          padding-left: 10px;
          display: flex;
        }

        .cardtitle::before {
          content: "";
          display: block;
          width: 6px;
          height: 20px;
          background-color: var(--primary);
          margin-right: 8px;
        }

        .overview {
          display: flex;
          flex-direction: column;
          height: 100%;

          .overviewitem {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            flex: 1;
            padding-left: 26px;
            padding-right: 26px;

            .overviewpic {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 56px;
            }

            .overviewdesc {
              display: flex;
              flex-direction: column;
              justify-content: center;
              gap: 14px;
              justify-content: center;
              font-size: 14px;
              font-weight: 400;
              letter-spacing: 0px;
              color: var(--lighterText);

              .desctitle {
                font-size: 16px;
                font-weight: 500;
                letter-spacing: 0px;
                color: var(--lightText);
              }

            }

            .overviewindex {
              display: flex;
              flex-direction: column;
              justify-content: center;
              gap: 14px;
              justify-content: center;

              .fakenum {
                width: 17px;
                height: 17px;
                background-color: transparent;
              }

              .num {
                width: 17px;
                height: 17px;
                line-height: 17px;
                text-align: center;
                color: #ffffff;
                border-radius: 50%;
                opacity: 1;
                background: var(--primary);
                cursor: pointer;
              }
            }
          }

          .overviewitem:nth-of-type(2) {
            border-top: 1px dashed rgba(233, 233, 233, 1);
            border-bottom: 1px dashed rgba(233, 233, 233, 1);
          }
        }
      }

      .logs {
        height: 100%;
        width: calc((100% - 20px)/2);
        position: relative;
        background: #ffffff;
        border-radius: 10px;
        padding: 30px 10px 0px 10px;

        .cardtitle {
          height: 20px;
          font-size: 18px;
          font-weight: bold;
          font-family: 'Microsoft Yahei';
          letter-spacing: 0px;
          color: var(--text);
          padding-left: 14px;
          display: flex;
          justify-content: space-between;

          .actionlink {
            float: right;
          }

          .title::before {
            content: "";
            display: block;
            width: 6px;
            height: 20px;
            background-color: var(--primary);
            margin-right: 8px;
          }
        }



        .loginput {
          position: relative;
          padding-left: 20px;
          padding-right: 20px;

          input {
            border-radius: 10px;
            background: #eeeeee;
            width: 100%;
            height: 28px;
            outline: none;
            border: transparent
          }

          img {
            width: 14px;
            height: 14px;
            position: absolute;
            right: 34px;
            top: 7px;
          }
        }

        .loglist {
          overflow: scroll;
          position: absolute;
          left: 10px;
          top: 60px;
          width: calc(100% - 20px);
          height: -webkit-fill-available;

          .log {
            font-size: 16px;
            font-weight: 400;
            letter-spacing: 0px;
            color: var(--lightText);
            height: 56px;
            padding-top: 14px;
            padding-bottom: 14px;
            padding-left: 20px;
            padding-right: 20px;
            box-sizing: border-box;
            border-bottom: 1px dashed rgba(233, 233, 233, 1);
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-line-clamp: 2;
            display: -webkit-box;
            word-break: break-all;
            -webkit-box-orient: vertical;
            cursor: pointer;

            span {
              display: inline;
              background: var(--primary);
              color: #ffffff;
              line-height: 16px;
              text-align: center;
              border-radius: 8px;
              padding-left: 8px;
              padding-right: 8px;
            }
          }

          &::-webkit-scrollbar {
            width: 3px !important;
          }

          &::-webkit-scrollbar-thumb {
            background: rgba(238, 238, 238, 1) !important;
            border-radius: 2.5px !important;
          }
        }
      }
    }
  }
}

::v-deep {
  .card-container {
    .card-title {
      justify-content: space-between;
      align-items: baseline;
    }

    .card-actions {
      display: none;
    }

    .card-body {
      .algopreview {
        width: 100%;
        height: 400px;

        img {
          width: 100%;
          height: 100%;
          display: block;
          object-fit: cover;
        }

      }

      .graphpreview {
        width: 100%;
        position: relative;
        display: flex;

        .cityselect {
          position: absolute;
          left: 0;
          width: auto;
          display: flex;
          align-items: center;
          z-index: 9999;

          label {
            width: -webkit-fill-available;
          }

          select {
            width: 100px;
          }
        }

        .gridgraph {
          display: grid;
          grid-template-rows: 1fr 1fr;
          grid-template-columns: repeat(3, 1fr);
          gap: 10px;
          height: 700px;
          width: 100%;
        }

        .graph {
          width: 100%;
          border: 1px solid #ccc;
        }
      }

      .warning {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 20px
      }
    }
  }
}
</style>
<style lang="scss">
.home-page {
  .search {
    align-items: center;
    display: flex;

    >INPUT {
      background-color: transparent;
      height: 30px;
      padding: 8px;
    }
  }

  h2 {
    font-size: 16px;
  }
}
</style>
