<template>
    <div class="centergraph" ref="mapref">
        <div class="graphtitle">{{ title }}</div>
        <div class="graphbox" ref="ies">
        </div>
    </div>
</template>
    
<script>
import debounce from 'lodash/debounce';
import * as echarts from "echarts";
import { indicatorapi } from '@/shell/components/home/indicator'

export default {
    name: 'IESGraph',
    props: {
        title: {
            type: String,
            dafault: ''
        },
        city: {
            type: String,
            dafault: ''
        }
    },
    data() {
        return { myChart: null, graphData: null }
    },
    created() {
        this.init()
    },
    watch: {
        city: function (o, n) {
            this.init()
        },
    },
    async mounted() {
        await this.$nextTick();
        let __resizeHandler = debounce(() => {
            if (this.myChart) {
                this.myChart.resize();
            }
        }, 100);
        window.addEventListener("resize", __resizeHandler);
    },
    methods: {
        init() {
            const url = indicatorapi.innovationEntrepreneurship
            const data = {
                "city": this.city === 'NANJING' ? '南京市' : '无锡市',
                "currentYear": 2022,
                "province": "江苏省"
            }
            this.$axios.post(url, data).then(async res => {
                this.graphData = await res.data.data
                await this.initChart(this.graphData)
            })
        },
        initChart(data) {
            this.myChart = echarts.init(this.$refs['ies']);
            // 绘制图表
            const districtNames = data.map(item => item.district);
            const indicatorNames = [
                "natlProvResOrgCnt",
                "rndStaffRate",
                "rndCostsRevenueRate",
                "patent_in_people_rate",
                "patent_in_rnd_cost_rate",
                "national_startup_agency_cnt",
                "new_register_co_cnt",
                "tech_sme_co_cnt",
                "incr_co_in_incubator_cnt",
                "startup_atmos_score"
            ];
            const IndicatorNames = [
                '国家级和省级研发机构数',
                '从业人员中研发人员全时当量数占比',
                '研发经费内部支出占营业收入比例',
                '每万人当年发明专利授权数',
                '当年每千万研发经费支出的发明专利申请数',
                '国家级创业服务机构数',
                '当年新注册企业数',
                '当年登记入信息库的科技型中小企业数',
                '当年孵化器、加速器和大学科技园内新增在孵企业',
                '区级创业氛围评分',
            ];

            const seriesData = indicatorNames.map(indicator => ({
                name: indicator,
                type: 'line',
                data: data.map(item => item[indicator]),
            }));
            const logAxisIndicators = ['rndStaffRate', 'patent_in_people_rate', 'incr_co_in_incubator_cnt'];
            const seriesDataWithLogAxis = seriesData.map(series => {
                if (logAxisIndicators.includes(series.name)) {
                    return { ...series, yAxisIndex: 1 };
                }
                return series;
            });
            this.myChart.setOption({
                legend: {
                    type: "scroll",
                    top: '0',
                    formatter: (name) => {
                        const index = indicatorNames.indexOf(name)
                        return IndicatorNames[index];
                    },
                },
                grid: {
                    left: '15%',
                    right: '15%',
                    show: true,
                    // backgroundColor:'#000'
                },
                xAxis: {
                    type: 'category',
                    data: districtNames,
                    boundaryGap: false,
                    axisLabel: {
                        interval: 0, // 强制显示所有标签
                        rotate: 45, // 标签旋转角度
                    },
                },
                yAxis: [{
                    type: 'value',
                }, {
                    type: 'log',
                }],
                series: seriesDataWithLogAxis,
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow',
                    },
                    formatter: (params) => {
                        const tooltipContentArr = []
                        params.map(param => {
                            const tooltipContent = `
                                ${IndicatorNames[indicatorNames.indexOf(param.seriesName)]}: ${param.value}
                            `;
                            tooltipContentArr.push(tooltipContent)
                        })

                        return tooltipContentArr.join('<br/>');
                    },
                },
            });
        }
    }
}
</script>
    
<style lang="scss" scoped >
.centergraph {
    width: 100%;
    height: 100%;

    .graphtitle {
        font-size: 14px;
        font-weight: 600;
        color: var(--lightText);
        height: 32px;
        width: 100%;
        line-height: 32px;
        margin: auto 0;
    }

    .graphbox {
        height: calc(100% - 32px);
        width: 100%;
        border-top: 1px solid #ccc;
    }
}
</style>
    