

<template>
	<svg>
        <image :xlink:href="icon" x="26" y="26" height="50" width="50" />
        <circle
            cx="51"
            cy="51"
            r="48"
            fill="none"
            stroke="#EEEEEE"
            stroke-width="3"
        />
        <circle
            cx="51"
            cy="51"
            r="48"
            fill="none"
            stroke-width="6"
            :style="{
                transform: 'rotate(-90deg)',
                transformOrigin: 'center',
                transformBox:'fill-box',
                stroke: config.color,

                strokeDasharray: `${currentLen} ${circleLength}`,
                transition: 'all 1s linear',
                strokeLinecap:'round',
            }"
        />
	</svg>
</template>

<script>
const circleLength = 2 * Math.PI * 50
const time = .8 
export default {
    name:'Ring',
    props:{
        config:{
            type:Object,
            default:()=>{}
        }
    },
    data() {
        return{
            currentT:0,
            currentLen:0,
            timeId:null,
            circleLength:circleLength,
            time:time
        }
    },
    computed:{
        icon(){
            const url = require('@/shell/assets/images/home/'+ this.config.type +'.png')
            return url
        }
    },
    watch:{
        config:function(val){
           this.currentLen = 2 * Math.PI * 50 * val?.rate
        }
    },
}




</script>

<style scoped>
.box {
	border: 1px solid red;
}
</style>
